<template>
  <div>
    <main
      class="min-h-screen bg-cover bg-top sm:bg-top"
      style="background-image: url('https://images.unsplash.com/photo-1533073526757-2c8ca1df9f1c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');">
      <div class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
        <div class="max-w-sm mx-auto">
          <p
            class=" text-8xl md:text-9xl font-black text-white
          font-semibold
          uppercase tracking-wide text-transparent
          bg-clip-text bg-gradient-to-r from-white">
            404
          </p>
        </div>
        <h1 class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
          <span>Uh oh! I think you’re lost.</span>
        </h1>
        <p
          class="mt-2 text-lg font-medium text-white text-opacity-90">
          It looks like the page you’re looking for doesn't exist.
        </p>
        <div class="mt-6">
          <router-link
            to="/"
            class="inline-flex items-center px-4 py-2
            border border-transparent text-sm font-medium
            rounded-md text-black text-opacity-75 bg-white
            bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50">
            Take me home
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: '404'
};
</script>

<style scoped>

</style>
